import React from 'react'
import PropTypes from 'prop-types'

import './Hero.css'

const Hero = ({ thumbnail, title }) => (
  <div className="hero">
    <div className="holder">
      <div className="container">
        <div className="hero__caption">
          <h2 aria-label={title} className="hero__title" data-splitting>{title}</h2>
        </div>
      </div>
      {(thumbnail && thumbnail.localFile) && (
        <picture>
          <source data-srcset={thumbnail.localFile.childImageSharp.sizes.src} />
          <img src={thumbnail.localFile.childImageSharp.sizes.tracedSVG} className="hero__img lazyload" alt={thumbnail.localFile.childImageSharp.sizes.originalName} />
        </picture>
      )}
    </div>
    <div className="hero__mouse-scroll">
      <span className="hero__mouse-movement" />
      <span className="hero__mouse-message">scroll</span>
    </div>
  </div>
)

Hero.propTypes = {
  thumbnail: PropTypes.object,
  title: PropTypes.string.isRequired,
}

export default Hero
