import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { TransitionState } from 'gatsby-plugin-transition-link'
import Content from '../components/Content'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import SEO from '../components/seo'
import ProgressBar from '../components/ProgressBar'
import '@wordpress/block-library/build-style/style.css'
import '@wordpress/block-library/build-style/theme.css'

class SingleProject extends Component {
  constructor() {
    super()

    this._isMounted = false

    this.state = {
      progressBar: 0,
    }
  }

  componentDidMount() {
    this._isMounted = true

    if (typeof window !== `undefined`) {
      this.handleProgressBar()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /**
   * Update read progress bar on scroll
   */
  handleProgressBar() {
    const { debounce } = require('../utils')

    window.addEventListener('scroll', debounce(() => {
      if (this._isMounted) {
        this.setState({
          progressBar: window.parseInt(100 * (window.pageYOffset / (document.body.scrollHeight - window.innerHeight))),
        })
      }
    }, 50))
  }

  render() {
    const { data: { project: {content, featured_media, title}}} = this.props
    const { progressBar } = this.state

    return (
      <TransitionState>
        {({mount, transitionStatus}) => {
          return (
            <Layout className={`single single-project`} breadcrumb={[
              {
                label: 'Les projets',
                link: '/',
                entry: .5,
                exit: .9,
                index: typeof this.props.location.state !== 'undefined' && this.props.location.state ? this.props.location.state.index : 0,
              },
              {
                label: title,
                link: false,
              }
            ]}>
              <SEO title={title} />
              <ProgressBar height={1} width={progressBar} />
              <Hero thumbnail={featured_media} title={title} />
              <Content content={content} />
            </Layout>
          )
        }}
      </TransitionState>
    )
  }
}

export default SingleProject

export const query = graphql`
  query($id: String) {
    project: wordpressWpProject(id: { eq: $id }) {
      content
      featured_media {
        localFile {
          childImageSharp {
            sizes(maxWidth: 1260) {
              originalName
              src
              tracedSVG
            }
          }
        }
      }
      title
    }
  }
  `
